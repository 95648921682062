<template>
  <div class="text-center">
    <template v-if="checkPermission('FeedbackResource.GET.Feedback')">
      <button
        @click="showFeedback()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Detail"
      >
        <i class="la la-eye"></i>
      </button>
    </template>
  </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();

export default {
  props: {
    feedbackVue: { type: Object },
    rowData: { type: Object },
    modalName: { type: String }
  },
  methods: {
    showFeedback() {
      this.feedbackVue.dataDetail = this.rowData;
      this.feedbackVue.showModalDetail();
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    }
  }
}
</script>
