<template>
  <div>
    <base-header base-title="Tanggapan"></base-header>
    <!-- begin:: Content -->
    <div
      class="kt-content kt-grid__item kt-grid__item--fluid"
      id="kt_content"
    >
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="headTitle"></base-title>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
              <auto-refresh
                :table-name="tableName"
                :on-refresh="fetch"
              ></auto-refresh>
            </div>
          </div>
        </div>
        <div
          class="kt-portlet__body"
          v-if="isLoaded"
        >
          <data-tables :table="table"></data-tables>
        </div>
        <div
          v-else
          class="m-auto p-5 d-flex flex-row h-100"
        >
          <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
          <h5 class="my-auto pl-5">Loading ...</h5>
        </div>
        <modal-detail
          v-if="isShowModalDetail"
          :show-modal.sync="isShowModalDetail"
          :modal-name="modalDetailName"
          :table-name="tableName"
          :modal-data="dataDetail"
        ></modal-detail>
      </div>
    </div>
    <!-- end:: Content -->
  </div>
</template>

<script>
import AutoRefresh from "./../_general/AutoRefresh";
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import DataTables from "./../_table/DataTables";
import BtnDetail from "./_components/BtnDetail";
import LoadSpinner from "./../_general/LoadSpinner";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const FeedbackRepository = RepositoryFactory.get("feedback");

export default {
  components: {
    AutoRefresh,
    DataTables,
    BaseTitle,
    BaseHeader,
    ModalDetail: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(
            import(
              /* webpackChunkName: "modal-feedback-detail" */ "./_components/ModalDetail.vue"
            )
          );
        }, 1000);
      }),
      loading: LoadSpinner
    })
  },
  data() {
    var vx = this;
    return {
      isLoaded: false,
      isShowModalDetail: false,
      dataDetail: false,
      modalDetailName: "modal_detail_feedback",
      headTitle: "Daftar Tanggapan",
      tableName: "table_feedback",
      modalText: "Tambah Tanggapan",
      modalIcon: "flaticon2-add-1",
      table: {
        tableName: "table_feedback",
        tableDataResult: [],
        tableColumnDefs: [
          {
            targets: 1,
            width: "15%",
            render: function (t, e, a, n) {
              var momentDate = moment(t);

              return momentDate.format("DD/MM/YYYY hh:mm:ss");
            }
          },
          {
            defaultContent: "-",
            targets: "_all"
          },
          {
            targets: 0,
            visible: false
          },
          {
            targets: -1,
            responsivePriority: 1
          }
        ],
        tableColumns: [
          {
            title: "ID",
            data: "id"
          },
          {
            title: "Waktu",
            data: "createdDate"
          },
          {
            title: "Nama",
            data: "patientName"
          },
          {
            title: "No HP",
            data: "noHp"
          },
          {
            title: "Tanggapan",
            data: "feedback"
          },
          {
            title: "Halaman",
            data: "page"
          },
          {
            title: "Versi App",
            data: "appVersion"
          },
          {
            title: "Jenis Perangkat",
            data: "deviceType"
          },
          {
            title: "Actions",
            data: "id",
            createdCell: function (cell, cellData, rowData, rowIndex, colIndex) {
              let btnDetail = Vue.extend(BtnDetail);
              let instance = new btnDetail({
                propsData: {
                  feedbackVue: vx,
                  rowData: rowData,
                  modalName: vx.modalName
                }
              });
              instance.$mount();
              $(cell)
                .empty()
                .append(instance.$el);
            }
          }
        ],
        tableFilter: [
          {
            title: "Nama",
            type: "text"
          },
          {
            title: "No HP",
            type: "text"
          },
          {
            title: "Tanggapan",
            type: "text"
          },
          {
            title: "Halaman",
            type: "combobox"
          },
          {
            title: "Versi App",
            type: "combobox"
          },
          {
            title: "Tanggal Lahir",
            type: "datepicker"
          },
          {
            title: "Jenis Perangkat",
            type: "text"
          },
          {
            title: "Actions",
            type: "actions"
          }
        ]
      }
    };
  },
  watch: {
    isShowModalDetail: function (val) {
      if (!val) {
        this.dataDetail = null;
      }
    }
  },
  methods: {
    async fetch() {
    //   const { data } = await FeedbackRepository.get();
        new Promise((resolve, reject) => {
            FeedbackRepository.get({}, resolve, reject );
        }).then(response => {
            const data = response.data;
            if (this.isLoaded) {
                var datatable = $("#" + this.tableName).DataTable();
                datatable.clear();
                datatable.rows.add(data);
                datatable.draw("full-hold");
            } else {
                this.table.tableDataResult = data;

                this.isLoaded = true;
            }
        }).catch(error => {
            var msg = (error.message) ? error.message : error;
            this.showToastError('Feedback : ' + msg);
        })
    },
    showModalDetail: function () {
      this.isShowModalDetail = true;
    },
    showToastError: function(message) {
        toastr.error(message);
    }
  },
  created() {
    this.fetch();
  }
};
</script>
